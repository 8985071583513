import { notify } from 'Components/common/notify';

import { sendCoralogixLog } from 'Utils/coralogix/log';
import { getBaseRequestOptions } from 'Utils/fetchUtils';
import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import PlatformUtils from 'Utils/platformUtils';
import {
	getCityCodes,
	getCurrentLanguageCode,
	getCurrentPage,
	getTotalCitiesCount,
	shouldFetch,
} from 'Utils/stateUtils';
import { getApiCDNBaseUrlV2 } from 'Utils/urlUtils';

import { decrementAPICount } from 'Actions/apiCount';
import {
	receiveCities,
	receiveNearByCities,
	requestCities,
	requestNearbyCities,
} from 'Actions/city';
import { purgeCityUrlCache } from 'Actions/fetch';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';

import { LOG_CATEGORIES, LOG_LEVELS } from 'Constants/constants';

const shouldFetchCities = (state: any) => {
	const currentCitiesCount = getCityCodes(state)?.length;
	const totalCities = getTotalCitiesCount(state);
	return !currentCitiesCount || currentCitiesCount < totalCities;
};

export const fetchCities = () => (dispatch: any, getState: any) => {
	const state = getState();
	const isDesktop = PlatformUtils.isDesktop();
	const platformParam = isDesktop ? 'DESKTOP' : 'WEB';
	const currentLanguageCode = getCurrentLanguageCode(getState());

	const url = `${getApiCDNBaseUrlV2({
		state,
	})}/api/v2/city/list/?platform=${platformParam}&language=${currentLanguageCode}`;

	dispatch(purgeCityUrlCache());
	dispatch(requestCities());
	const options = getBaseRequestOptions(getState());

	if (!shouldFetchCities(state)) return Promise.resolve();

	return fetch(url, options)
		.then(response => response.json())
		.then(citiesList => {
			citiesList.forEach((city: any) => {
				const urlSlugsObject = city.urlSlugs;

				// Replacing '/' with '#' in urlSlugs to prevent crawlers from crawling the url
				Object.keys(urlSlugsObject).forEach(lang => {
					const urlSlug = urlSlugsObject[lang];
					urlSlugsObject[lang] = urlSlug.replaceAll('/', '#');
				});
			});
			dispatch(receiveCities(citiesList, url));
		})
		.catch(err => {
			dispatch(setAPIServerAPIStatus(url, err.status));
			error(err);
			dispatch(decrementAPICount());
			notify.showNetworkError(err);
		});
};

export const fetchNearByCities =
	// @ts-expect-error TS(7031): Binding element 'cityLatitude' implicitly has an '... Remove this comment to see the full error message


		({ cityLatitude, cityLongitude, cityCode }) =>
		(dispatch: any, getState: any) => {
			if (!cityLatitude || !cityLongitude) {
				sendCoralogixLog({
					title: `cityLatitude or/and cityLongitude not defined`,
					severity: LOG_LEVELS.WARNING as any,
					methodName: 'fetchNearByCities',
					category: LOG_CATEGORIES.API_ERROR,
				});
				return;
			}

			const url = `${getApiCDNBaseUrlV2({
				state: getState(),
			})}/api/v2/city/list/location?latitude=${cityLatitude}&longitude=${cityLongitude}`;
			if (!shouldFetch(getState(), url, 120)) return Promise.resolve();
			dispatch(requestNearbyCities());
			const options = getBaseRequestOptions(getState());

			return fetch(url, options)
				.then(response => response.json())
				.then(json => {
					const cityCodes = json
						.filter(
							(city: any) =>
								city.discoverable && city.cityCode !== cityCode,
						)
						.map((city: any) => city.cityCode);
					dispatch(receiveNearByCities(cityCodes, url));
				})
				.catch(err => {
					error(err);
					dispatch(decrementAPICount());
					notify.showNetworkError(err);
				});
		};
