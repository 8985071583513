import { v4 as uuidv4 } from 'uuid';

/*Utils */
import { isProductionEnvironment } from 'Utils/envUtils';

import { LOG_CATEGORIES, LOG_LEVELS } from 'Constants/constants';

import { sendCoralogixLog } from './coralogix/log';
import resolveResponse from './resolveResponse';

const FETCH_PARAMS = {
	credentials: 'include',
	referrerPolicy: 'no-referrer-when-downgrade',
};

const HSID_HEADER = 'X-H-SID';
const TRACE_TOKEN_HEADER = 'x-client-trace-id';

const TRAILING_SLASH_SOURCES = [
	'api.headout.com',
	'api.test-headout.com',
	'headout.com/api',
	'test-headout.com/api',
];

const addTrailingSlash = (url?: string) => {
	if (url && TRAILING_SLASH_SOURCES.some(domain => url.includes(domain))) {
		const [path, query] = url.split('?');
		let finalUrl = path.endsWith('/') ? path : `${path}/`;
		if (query) finalUrl += `?${query}`;

		return finalUrl;
	}

	return url;
};

const fetchWrapper = (
	source?: string,
	{ hsid, ...options }: Record<string, any> = {},
) => {
	const sourceWithTrailingSlash = addTrailingSlash(source);
	const traceToken = uuidv4();

	const reqHeaders = {
		...(typeof options?.headers === 'object' ? options.headers : {}),
		...(hsid && {
			[HSID_HEADER]: hsid,
		}),
	};

	if (Object.keys(reqHeaders).length) {
		options.headers = reqHeaders;
	}

	return fetch(
		// @ts-expect-error TS(2769): No overload matches this call.
		sourceWithTrailingSlash,
		Object.assign({}, FETCH_PARAMS, options),
	)
		.then(async response => {
			sendCoralogixLog({
				title: `Error fetching data`,
				severity: LOG_LEVELS.ERROR as any,
				methodName: 'fetchWrapper',
				category: LOG_CATEGORIES.API_ERROR,
				traceToken: traceToken,
			});
			return resolveResponse(response);
		})
		.catch(async errJSON => {
			if (errJSON.status >= 400 && isProductionEnvironment()) {
				sendCoralogixLog({
					title: `Error fetching data`,
					severity: LOG_LEVELS.ERROR as any,
					methodName: 'fetchWrapper',
					metaData: errJSON,
					category: LOG_CATEGORIES.API_ERROR,
					traceToken: traceToken,
				});
			}
			return Promise.reject(errJSON);
		});
};

export default fetchWrapper;
